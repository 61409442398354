import React, { useEffect, useState, useRef } from 'react';
import { ROUTE_CONTRACTS_PAGE } from "../../routes/Routes";
import { useNavigate } from "react-router-dom";
import { ToolbarForm } from '../toolbar-form/toolbar-form'
import { ContractFormDetails } from './ContractFormDetails';
import ValidationGroup from 'devextreme-react/validation-group';
import ClosingAlert from '../../components/ClosingAlert';
import { Content } from '../../Enums/contents';
import PutPostPatchFetch from '../../hooks/PutPostPatchFetch';
import './ContractForm.scss';
//import { withLoadPanel } from '../../../utils/withLoadPanel';

//const ContractFormDetailsWithLoadPanel = withLoadPanel(ContractFormDetails);

/** @typedef {import('../../types/contract').Contract} Contract */

/**
 * @param {Contract} data 
 * @param {boolean} isLoading 
 * @param {boolean} isEditing 
 */
export const ContractForm = ({data, isEditing, isLoading = false}) => {
  const navigate = useNavigate();
  const navigateToContractPage = () => navigate(ROUTE_CONTRACTS_PAGE.withSlash);
  const [editing, setEditing] = useState(isEditing);
  const [formData, setFormData] = useState(data);
  const [contractSaved, setContractSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isNew = data["ContractId"] == 0;
  const dataRef = useRef(null);
  const {
    Alerts: {
      ContractSavedSuccessful
    },
    Buttons: {
        MUI: {
            Severity: {
                success,
                warning
            }
        }
    }
} = Content;

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleEditClick = (e) => {
    if(editing === false && formData) {
      dataRef.current = formData;
    } else {
      dataRef.current = undefined;
    }
    setEditing(!editing);
  };

  const onSaveClick = async ({ validationGroup }) => {
    var formValid = validationGroup.validate().isValid;
    
    if (formValid === false)
      return;

    if (formValid === true) {
      console.log("Saving Contract: ", formData);
      setEditing(false);

      var result;
      if (isNew)
        result = await PutPostPatchFetch('/v1/Contract/AddContract', 'POST', formData);
      else
        result = await PutPostPatchFetch('/v1/Contract/EditContract', 'PUT', formData);

      if (result?.Success) {
        setContractSaved(true);
        setError(false);
      }
      if (result?.Errors.length > 0) {
        setContractSaved(false);
        setError(true);
        setErrorMessage(result?.Errors[0]?.Message);
      }
    }
  };

  const onBackClick = () => {
    navigateToContractPage();
  };

  const onCancelClick = () => {
    if (isNew) {
      navigateToContractPage();
    }
    else {
      //setFormData(formDataDefaults);
      setFormData(dataRef.current);
      handleEditClick();
      //navigate(0);
    }
  };

  const updateField = (field, value) => {
    if(!formData) 
      return;
    console.log(formData);
    console.log(field);
    console.log(value);
    formData[field] = value;
    //setFormData({ ...formData, ...{ [field]: value } });
    console.log(formData);
  };

  const updateObject = (item, key, field, value) => {
    if(!formData) 
      return;
    console.log(formData);
    console.log(field);
    console.log(value);
    formData[item][key] = {...formData[item][key], [field]: value};
  //  setFormData(formData);
  //   const arr = [];
  //   const obj = {field: value};
  //   arr[pos] = obj;
  //   formData[item] = arr
  //  Object.keys
    //setFormData({ ...formData, ...{ [field]: value } });
    console.log(formData);
  };

  return (
    <div className='contact-form'>
      <ValidationGroup>
        <ToolbarForm toggleEditing={handleEditClick} onSaveClick={onSaveClick} editing={editing} onBackClick={onBackClick} onCancelClick={onCancelClick} />
        <ContractFormDetails
          loading={isLoading}
          hasData={!formData}
          data={formData}
          editing={editing}
          updateField={updateField}
          updateObject={updateObject}
        //   panelProps={{
        //     container: '.contact-form',
        //     position: { of: '.contact-form' },
        //   }}
        />
      </ValidationGroup>
      <ClosingAlert
        clickableIcon={navigateToContractPage}
        enableAlertIcon={true}
        message={ContractSavedSuccessful}
        severity={success}
        visible={contractSaved}
      />
      <ClosingAlert
        hideAlert={() => { setError(false) }}
        message={errorMessage}
        severity={warning}
        visible={error}
      />
    </div>
  );
};