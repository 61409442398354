import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePageTitle from "../../hooks/usePageTitle";
import { ROUTE_SECURITY_DOCUMENTS, ROUTE_WELCOME } from "../../routes/Routes";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { List } from 'devextreme-react/list';
import notify from "devextreme/ui/notify";
import ClosingAlert from "../../components/ClosingAlert";
import { DateBox } from "devextreme-react";
import { Toast } from "devextreme-react/toast";
import { fileSizeConversion } from "../../util/FileSizeConversion";
import { BaseButton } from "../../components/BaseButton/BaseButton";
import 'devextreme/dist/css/dx.light.css';
import FiscalYearDropdown from '../../FyCostEstimates/AddSecurityCost/FiscalYearDropdown';
import './SecurityDocumentTransfer.scss';
import { ValidationMessageItem } from "./ValidationMessageItem/ValidationMessageItem";

const SecurityDocumentsTransfer = () => {
    const { title } = ROUTE_SECURITY_DOCUMENTS;
    usePageTitle(title);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fiscalYear, setFiscalYear] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [validationMessage, setValidationMessage] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);

    const navigate = useNavigate();
    const navigateToRoute = () => navigate(ROUTE_WELCOME.withSlash);
    const fileInputRef = useRef(null);

    usePageTitle(title);

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        const { size } = event.target.files[0];
        const maxFileSize = 25000000; // 25 MB

        if (size && size >= maxFileSize) {
            notify(
                "The file you have selected is too large in size. Please choose a .csv file with a size that is under 25 MB.", 
                "error", 
                4000
            );
        };
        
        if (size && size < maxFileSize) {
            notify(
                "File selection successful.", 
                "success", 
                2000
            );
            setSelectedFiles(prevFiles => [...prevFiles, ...files]);
        };

        fileInputRef.current.value = "";
    };

    const RemoveSelectedFiles = async (event) => {
        event.preventDefault();
        selectedFiles.length = 0;
        setSelectedFiles(prevFiles => [...prevFiles, ...selectedFiles]);
     
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();

        for (let i = 0; i < selectedFiles.length; i++) { 
            formData.append("Files", selectedFiles[i])
        };
      
        
        try {
            const { Errors, Message, Success } = 
                await PutPostPatchFetch(
                    `/v1/BasicSecurity/UploadSecurityDocuments?billingDate=${String(fiscalYear)}`, 
                    'POST', 
                    formData, 
                    'multipart/form-data'
                );

            if (Success) {
                setAlertVisible(true);
                setErrorMessage(false);
                setSuccessMessage('Files submitted successfully!');
            } ;
            
            if (Errors) {
                setValidationMessage(Errors[0].ErrorMessages);
                setSuccessMessage(null);
                setErrorMessage(true);
            };
        } catch (error) {
            console.error('Error: ', error);
        }

    };

    const handleCloseAlert = () => {
        setAlertVisible(false);
    };

    return (
        <div>
            <h1> { title } </h1>
            <div className="block block__div--wrapper">
            <FiscalYearDropdown 
                    enableSearch={true}
                    inputAttribute={{ "aria-label": "fiscal year select" }}
                    label={"Fiscal Year"}
                    optionUpdatedByUser={({ selectedItem }) => setFiscalYear(selectedItem)}
                    value={fiscalYear}
                    isVisible={true}
                />
            </div>
            <div className="block block__div--wrapper">
                <BaseButton
                    label={"Select file"}
                    onClick={() => document.getElementById('file-upload-input').click()}
                    variant={"outlined"}
                />
                <input
                    accept=".csv"
                    id="file-upload-input"
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    type="file"
                />
                <BaseButton
                    aria-disabled={selectedFiles.length === 0}
                    disabled={selectedFiles.length === 0}
                    onClick={handleSubmit}
                    variant={"outlined"}
                    label={"Submit"}
                />
            </div>
            <div className="block">
                <div className={
                    selectedFiles.length === 0 ? 
                    "block__unscrollable-container" 
                    : 
                    "block__scrollable-container"
                    }>
                    {
                        !selectedFiles.length === 0 && 
                        <div className="inline__empty-list">                            
                            <span>
                                No File Selected
                            </span>
                        </div>
                    }
                    {
                        selectedFiles.length > 0 &&
                        <ul className="block__unordered-list">
                            {
                                selectedFiles.map(({
                                    index,
                                    name, 
                                    size
                                }) => {
                                    const formattedSize = fileSizeConversion(size);
                                    return (
                                        //TODO: Using name instead of index gets rid of error, but need to find a better indexing method
                                        <li key={name} className="inline__list-item">
                                            <span className="inline__span--file-name">
                                                {name} 
                                            </span>
                                            <span className="inline__span--file-size">
                                                {formattedSize}
                                            </span>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    }
                </div> 
            </div>
               
            <div className="block block__div--wrapper">
                <BaseButton
                    label={"cancel"}
                    onClick={RemoveSelectedFiles}
                    variant={"outlined"}
                />
            </div>

            {
                errorMessage &&
                <div className="list-container">
                    <>
                        <h2 className="h2--validation-errors"> Validation Errors </h2>
                        <List
                            dataSource={validationMessage}
                            height="100%"
                            itemRender={ValidationMessageItem}
                        />
                    </>
                </div>
            }            

            <ClosingAlert
                visible={alertVisible}
                hideAlert={handleCloseAlert}
                message={successMessage || errorMessage}
                severity={successMessage ? 'success' : 'error'}
                enableAlertIcon={true}
                clickableIcon={handleCloseAlert}
            />
        </div>

    );
};

export default SecurityDocumentsTransfer;
